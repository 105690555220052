.hero {
  padding-top: 9rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  height: 100vh;
  background-color: var(--dark-purple);
  color: var(--white);
  text-align: center;
  position: relative;
  overflow: hidden;
}

.heading {
  font-size: 6rem;
  font-weight: 700;
  margin: 0;
  z-index: 4;
}

.jobTitle {
  font-size: 3rem;
  font-weight: 700;
  z-index: 4;
}

.art1 {
  position: fixed;
  top: 20%;
  right: 10%;
  width: 30rem;
  z-index: 2;
}

.art2 {
  position: fixed;
  top: 20%;
  left: 10%;
  width: 30rem;
  z-index: 2;
}

.logo {
  position: absolute;
  top: 75%;
  left: 28%;
  width: 16rem;
}

.particle {
  position: absolute;
  width: 5rem;
  height: 0.4rem;
  background-color: var(--yellow);
  border-radius: 50rem;
  animation: particles 0.5s infinite;
  animation-delay: 0.3s;
}

@keyframes particles {
  50% {
    opacity: 0;
  }
}

.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  height: 100vh;
  background-color: var(--white);
  color: var(--white);
  text-align: center;
  position: relative;
  overflow: hidden;
  padding-top: 10rem;
  z-index: 4;
}

.about__content {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.about__image {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 44rem;
  background-color: var(--light-pink);
  width: 30rem;
  height: 30rem;
  position: relative;
  z-index: 2;
}

.myPic {
  width: 100%;
}

.about__info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 3;
  position: relative;
}

.aboutName {
  font-size: 8rem;
  font-weight: 700;
  color: var(--dark-purple);
}

.aboutTitle {
  font-size: 4rem;
  font-weight: 700;
  color: var(--light-pink);
}

.about__social {
  display: flex;
  gap: 2rem;
  position: relative;
  z-index: 2;
  margin-top: 4rem;
}

.about__art1 {
  position: absolute;
  top: 20%;
  right: 10%;
  width: 30rem;
}

.about__art2 {
  position: absolute;
  top: 5%;
  left: 10%;
  width: 30rem;
}

.work {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  min-height: 60vh;
  background-color: var(--dark-purple);
  text-align: center;
  position: relative;
  overflow: hidden;
  z-index: 4;
  padding: 3rem;
}

.workTitle {
  font-size: 5rem;
  font-weight: 700;
  color: var(--yellow);
}

.work__content {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.workButton {
  margin-top: auto;
}

.about__btn {
  margin-top: auto;
  margin-bottom: 3rem;
}

.contact {
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  background-color: var(--white);
  text-align: center;
  position: relative;
  overflow: hidden;
  z-index: 4;
}

.contactTitle {
  font-size: 5rem;
  font-weight: 700;
  color: var(--dark-purple);
  margin: 3rem 0 0;
}

.contact__form {
  width: 60rem;
  max-width: 100rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem;
  align-items: center;
}

.contact__formField {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
  width: 100%;
}

.contact__control {
  width: 100%;
  box-sizing: border-box;
  padding: 1.5rem;
  border-radius: 1rem;
  border: 1px solid var(--dark-purple);
  outline: none;
  transition: all 0.3s ease-in-out;
}

.contact__control:focus {
  border: 1px solid var(--yellow);
  box-shadow: 0 0 0 2px var(--yellow);
}

.successMessage {
  font-size: 3rem;
  font-weight: 700;
  color: var(--dark-purple);
}

@media screen and (max-width: 768px) {
  .about__art1,
  .art1,
  .about__art2,
  .art2 {
    width: 15rem;
  }

  .about,
  .contact {
    height: 110vh;
  }

  .contact {
    justify-content: flex-start;
  }

  .about__content {
    flex-direction: column;
    margin-top: 2rem;
  }

  .aboutName {
    margin: 0;
    font-size: 6rem;
  }

  .contact__form {
    width: 70%;
    padding: 0;
  }
}

@media screen and (max-width: 375px) {
  .heading {
    font-size: 4rem;
  }

  .jobTitle {
    font-size: 2rem;
    margin: 0;
  }

  .art1 {
    width: 9rem;
    top: unset;
    bottom: 3rem;
  }

  .art2 {
    width: 9rem;
  }

  .logo {
    top: 36%;
  }

  .about {
    height: 115vh;
  }

  .about__art1 {
    top: unset;
    width: 9rem;
    bottom: 35rem;
  }

  .about__art2 {
    top: 42rem;
    width: 9rem;
  }

  .about__image {
    margin-top: 3rem;
  }

  .aboutName {
    margin: 0;
    font-size: 5rem;
  }

  .aboutTitle {
    font-size: 3rem;
  }

  .workTitle {
    font-size: 3rem;
    margin-top: 3rem;
  }
}
