.hero {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: var(--light-pink);
  padding: 3rem;
  position: relative;
  overflow: hidden;
  margin-top: 6rem;
}

.heroContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  width: 50%;
  position: relative;
  z-index: 1;
}

.heroTitle {
  font-size: 7rem;
  font-weight: 800;
  color: var(--white);
}

.heroTitle span:first-child {
  font-size: 10.5rem;
}

.heroTitle span:nth-child(2) {
  color: var(--yellow);
}

.heroLogo {
  width: 20rem;
}

.heroArtContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.heroArt {
  width: 60%;
}

.portfolio {
  overflow: hidden;
}

.portfolioBody {
  background-color: var(--dark-purple);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 5rem 0;
  gap: 4rem;
}

@media (max-width: 768px) {
  .hero {
    flex-direction: column;
    padding: 2rem;
    height: auto;
  }

  .heroContent,
  .heroArtContainer {
    width: 100%;
  }

  .heroArt {
    display: none;
  }
}

@media (max-width: 500px) {
  .heroTitle {
    font-size: 5rem;
  }

  .heroTitle span:first-child {
    font-size: 7rem;
  }

  .heroLogo {
    align-self: flex-start;
  }

  .heroArt {
    display: block;
    position: absolute;
    top: 35%;
    right: -5rem;
    transform: rotate(-45deg);
  }
}
