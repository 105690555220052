.workCard {
  position: relative;
  width: 320px;
  max-width: 100%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}

.workCard .workCard__image {
  width: 100%;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}

.workCard:hover {
  transform: rotate(-5deg) scale(1.1);
  box-shadow: 0 10px 20px var(--color3);
}

.workCard__content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;
  opacity: 0;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}

.workCard:hover .workCard__content {
  transform: translate(-50%, -50%) rotate(0deg);
  opacity: 1;
}

.workCard__title {
  margin: 0;
  font-size: 24px;
  color: #333;
  font-weight: 700;
}

.workCard__description {
  margin: 10px 0 0;
  font-size: 14px;
  color: #777;
  line-height: 1.4;
}

.card:hover svg {
  scale: 0;
  transform: rotate(-45deg);
}
