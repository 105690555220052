:root {
  /* Set 1rem to 10px */
  font-size: 62.5%;

  --dark-purple: #2f1463;
  --yellow: #fff34f;
  --pink: #b970f8;
  --light-pink: #ab76f3;
  --purple: #3c0b9a;
  --white: #fff;
}
