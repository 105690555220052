.about {
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  height: 100vh;
}

.about section {
  scroll-snap-align: start;
}

.hero {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100vh;
  background-color: var(--yellow);
  padding: 3rem;
  position: relative;
  overflow: hidden;
  padding-bottom: 0;
}

.heroContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  width: 50%;
  position: relative;
  z-index: 1;
  height: 100%;
  margin-top: auto;
}

.heroLogo {
  width: 14rem;
}

.heroJob {
  font-size: 5rem;
  font-weight: 700;
  color: var(--dark-purple);
  margin: 0;
}

.heroDescription {
  font-size: 2.5rem;
  font-weight: 600;
  color: var(--dark-purple);
  text-align: center;
}

.heroImageContainer {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 2rem;
  position: relative;
  z-index: 1;
  height: 100%;
}

.heroImage {
  margin-top: auto;
  width: 50rem;
}

.heroArt {
  width: 43rem;
  position: absolute;
  top: 20%;
  right: -4%;
}

.skills {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  width: 100%;
  height: 100vh;
  background-color: var(--dark-purple);
  text-align: center;
  position: relative;
  overflow: hidden;
  padding-block: 3rem;
}

.skillsHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.skillstitle {
  font-size: 5rem;
  font-weight: 700;
  color: var(--yellow);
}

.skillsGlasses {
  width: 24rem;
}

.skills__content {
  display: flex;
  justify-content: center;
  gap: 2rem;
  width: 100%;
}

.skillsSide {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  width: 50%;
}

.skillsSideTitle {
  font-size: 3rem;
  font-weight: 700;
  color: var(--white);
}

.skillsSide__content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  flex-wrap: wrap;
}

.follow {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  height: 100vh;
  background-color: var(--white);
  text-align: center;
  position: relative;
  overflow: hidden;
}

.followTitle {
  font-size: 5rem;
  font-weight: 700;
  color: var(--dark-purple);
  position: relative;
  z-index: 3;
}

.about__social {
  display: flex;
  gap: 2rem;
  position: relative;
  z-index: 2;
  margin-top: 4rem;
}

.followLogo {
  width: 20rem;
}

.about__art1 {
  position: absolute;
  top: 30%;
  right: 10%;
  width: 30rem;
}

.about__art2 {
  position: absolute;
  top: 70%;
  left: 10%;
  width: 30rem;
}

@media (max-width: 900px) {
  .hero {
    height: auto;
  }

  .hero {
    flex-direction: column;
    padding: 2rem;
    height: auto;
    padding-bottom: 0;
    padding-top: 11rem;
  }
  .heroContent,
  .heroArtContainer {
    width: 100%;
  }

  .heroArt {
    top: 55%;
  }

  .about__art1 {
    right: -10%;
  }

  .about__art2 {
    left: -10%;
  }
}

@media (max-width: 420px) {
  .hero {
    height: 85vh;
  }

  .heroLogo {
    width: 9rem;
  }

  .heroJob {
    font-size: 3rem;
    margin: 0;
  }

  .heroDescription {
    margin: 0;
    font-size: 1.5rem;
  }

  .heroImage {
    width: 20rem;
  }

  .heroArt {
    width: 30rem;
    right: -25%;
  }

  .skillsSideTitle {
    font-size: 2rem;
  }

  .about__art1 {
    width: 15rem;
    top: 0;
    right: -14%;
  }

  .about__art2 {
    width: 15rem;
    bottom: 0;
  }
}
